import "./Friends.css";
import {
  Hero,
  PageCaption,
  ProfileItem,
  Br,
  BackToAbout,
} from "../../components";
import backgroundImage from "../../images/friends-hero.jpg";
import Nav from "../../layout/Nav";
import { ROLES } from "../../api";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";

export default function Friends() {
  usePageTracking({
    title: "Friends of Hola",
  });

  const { data } = useQuery(ROLES, {
    variables: {
      where: {
        roleType: {
          name: "FRIENDS_OF_HOLA",
        },
      },
    },
  });

  // const friends = [
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Jon Salinas, Vice President",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  // ];

  const roles = data?.roles || [];

  return (
    <div className="Friends">
      <Nav />
      <Hero
        image={backgroundImage}
        text={
          <div style={{ textAlign: "center" }}>
            Friends <Br />
            of Hola
          </div>
        }
      />
      <PageCaption
        text={
          <span>
            <span className="bold">
              A 501(c)(3) organization, FOH is an all-volunteer, parent-run,
              non-profit organization created to benefit HoLa.
            </span>
          </span>
        }
      />

      <div className="section app-grid" style={{ paddingBottom: "72px" }}>
        <div className="section-content">
          <div className="section-p">
            As a top-performing public school (top 5% in NJ), HoLa serves nearly
            15% of Hoboken's K-8 public school students. However, it receives
            the lowest per-student funding of any public school in New Jersey,
            placing it in the bottom 5%. Despite this challenge, HoLa continues
            to deliver world-class bilingual education thanks to the generosity
            and support of our community through fundraising efforts of the
            Friends of HoLa.
          </div>
          <div className="section-p">
            Friends of HoLa (FOH) is a 501(c)(3) all-volunteer, parent-led
            non-profit organization dedicated to advancing HoLa’s mission of
            providing high-quality bilingual education. FOH supports key school
            initiatives, enhances staff development, and enriches the
            educational experience for students. Since its founding in 2016, FOH
            has raised over $1 million to support critical programs and
            initiatives, while fostering a strong sense of community at HoLa.
          </div>
          {/* <ul className="section-ul">
            <li>
              HoLa serves nearly 15% of Hoboken's K-8 public school students
              and, despite receiving the lowest per-student funding of any
              public school in Hoboken, including other local charters, it
              continues to deliver a world-class bilingual education.
            </li>
            <li>
              Due to the lack of funding from the State of New Jersey for
              building and facilities costs for charter schools, HoLa must spend
              nearly 12% of its per-student funding, or $1,500 per student per
              year, on facilities alone.{" "}
            </li>
          </ul> */}
          <div className="section-p">
            Our annual budget relies heavily on the generosity of HoLa families
            and donors, with every dollar directly enhancing our children’s
            school experience and empowering our educators to uphold the
            school’s mission. The Annual Appeal, organized by FOH, is a key
            opportunity for our community to unite and demonstrate its
            generosity. The appeal kicks off annually on Giving Tuesday and runs
            through March 31st. Every family's participation—regardless of the
            amount—is vital, and 100% participation is a core goal of the Annual
            Appeal.
          </div>

          <div className="section-p">
            We encourage all HoLa families to participate, whether through
            financial contributions or by donating your time. Parent involvement
            is what makes our community vibrant and strong. To explore volunteer
            opportunities that align with your interests and schedule, please
            reach out to us at friends@holahoboken.org.
          </div>

          <div className="BackToAbout__action">
            <Link to="/donate" className="section-cta">
              Donate Today
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="section app-grid" style={{ background: "#F8F8F8" }}>
        <div className="section-content">
          <div className="section-title">Advancing our staff</div>
          <ul className="section-ul">
            <li>
              Sponsoring education courses and development for HoLa’s teachers
            </li>
            <li>
              Funding the Artists in Residence Program (yoga, music, art, and
              dance)
            </li>
            <li>
              Hosting numerous faculty/staff appreciation events throughout the
              school year to celebrate their talent and dedication to our
              children
            </li>
          </ul>
        </div>
      </div>

      <div className="section app-grid" style={{ background: "#F8F8F8" }}>
        <div className="section-content">
          <div className="section-title">Connecting our Community</div>
          <ul className="section-ul">
            <li>
              Producing various community events throughout the year, such as
              Dia de Los Muertos, International Night, Sabor de HoLa (our annual
              gala), and more
            </li>
            <li>
              Enhancing communication between the school, teachers and parents
              through our FOH class parents
            </li>
            <li>
              Funding the school’s Diversity, Inclusion, Community & Equity
              (DICE) committee events and scholarships
            </li>
            <li>
              Organizing Community Outreach and activities that support local
              charitable organizations including HoLa Cares and Hudson Gives
            </li>
          </ul>
        </div>
      </div>

      <div
        className="section app-grid"
        style={{ background: "#F8F8F8", paddingBottom: "72px" }}
      >
        <div className="section-content">
          <div className="section-title">
            Enhancing our Children’s Experience
          </div>
          <ul className="section-ul">
            <li>
              Identifying funding opportunities as recommended by the school’s
              administration including classroom furniture, classroom libraries
              and more
            </li>
            <li>
              Sponsoring education events and activities such as children’s
              reading initiatives, Summer Success Program, Yoga, Dance and
              Instrumental classes{" "}
            </li>
            <li>
              Leveraging teaching tools such as digital learning app
              subscriptions, Chromebooks and technology
            </li>
            <li>Building a capital fund for our own school building </li>
          </ul>
        </div>
      </div> */}

      {/* <div className="section app-grid">
        <div className="section-content">
          <div className="section-title">
            Our annual budget is raised through generous donations from the
            school’s families and donors.
          </div>

          <div className="section-p">
            Every dollar raised enhances our children’s school experience and
            supports our educators in fulfilling the school’s mission.
          </div>

          <div className="section-p">
            We urge all HoLa families to get involved and support the school
            with their time and/or monetary donations. Parent involvement is a
            fundamental part of what makes a school community rich and robust.
            Email us at friends@holahoboken.org to get involved in volunteer
            opportunities that work with your interests and schedule.
          </div>

          <div className="section-cta-wrapper">
            <Link to="/donate" className="section-cta">
              Donate Today
            </Link>
          </div>
        </div>
      </div> */}

      <div className="Friends__list app-grid">
        <div className="section-content">
          <div className="section-title">Friends of HoLa Board</div>
        </div>

        {roles.map((profile) => (
          <ProfileItem profile={profile} />
        ))}
      </div>

      <BackToAbout />
    </div>
  );
}
