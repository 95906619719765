import "./Home.css";
import { useState, useRef, Fragment } from "react";
import {
  motion,
  useInView,
  useScroll,
  useMotionValueEvent,
  useTransform,
} from "framer-motion";
import { DonutChart } from "../../components";
import Nav from "../../layout/Nav";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";

function HomeDescription() {
  const ref = useRef(null);
  const linksRef = useRef(null);
  const isInView = useInView(ref, { once: true });
  const linksIsInView = useInView(linksRef, { once: true });
  // console.log(linksIsInView);

  return (
    <div className="Home__desc app-grid">
      <div className="Home__desc__content" ref={ref}>
        <div
          className="Home__desc__content__primary"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa is a US News & World Report top New Jersey Elementary & Middle
          School (Private & Public).
        </div>

        <div
          className="Home__desc__content__secondary"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa is annually classified by the New Jersey Department of Education
          as a top 5% performing school and is designated as a Dual Language
          Model Program for the state. In 2024, the Spanish Consulate recognized
          HoLa as an International Spanish Academy.
        </div>

        <div
          className="Home__desc__content__secondary"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Our Middle School is the first in the state to have its curriculum
          approved for the College Board’s AP exam program, enabling students to
          take the Spanish AP exam in 8th grade to qualify for college credits.
        </div>

        <div className="Home__desc__content__links" ref={linksRef}>
          {linksIsInView && (
            <Fragment>
              <a
                href={
                  "https://www.usnews.com/education/k12/new-jersey/hoboken-dual-language-charter-school-232484"
                }
                target="_blank"
                rel="noreferrer"
                className="Home__desc__content__links__link"
              >
                <motion.img
                  variants={{
                    initial: {
                      opacity: 0,
                      y: "8px",
                    },
                    final: {
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 0.4,
                        delay: 0.2,
                      },
                    },
                  }}
                  initial="initial"
                  animate="final"
                  src={"home-news-1.png"}
                />
              </a>

              <a
                href="https://www.usnews.com/education/k12/new-jersey/hoboken-dual-language-charter-school-232484"
                target="_blank"
                rel="noreferrer"
                className="Home__desc__content__links__link"
              >
                <motion.img
                  variants={{
                    initial: {
                      opacity: 0,
                      y: "8px",
                    },
                    final: {
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 0.4,
                        delay: 0.3,
                      },
                    },
                  }}
                  initial="initial"
                  animate="final"
                  src={"home-news-2.png"}
                />
              </a>

              <a
                href="https://ap.collegeboard.org/"
                target="_blank"
                rel="noreferrer"
                className="Home__desc__content__links__link"
              >
                <motion.img
                  variants={{
                    initial: {
                      opacity: 0,
                      y: "8px",
                    },
                    final: {
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 0.4,
                        delay: 0.4,
                      },
                    },
                  }}
                  initial="initial"
                  animate="final"
                  src={"home-news-3.png"}
                />
              </a>

              <a href="" className="Home__desc__content__links__link">
                <motion.img
                  variants={{
                    initial: {
                      opacity: 0,
                      y: "8px",
                    },
                    final: {
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 0.4,
                        delay: 0.4,
                      },
                    },
                  }}
                  initial="initial"
                  animate="final"
                  src={"home-news-6.jpg"}
                  style={{
                    height: "160px",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                />
              </a>

              {/* <a
                href="https://sealofbiliteracy.org/index.php"
                target="_blank"
                rel="noreferrer"
                className="Home__desc__content__links__link"
              >
                <motion.img
                  variants={{
                    initial: {
                      opacity: 0,
                      y: "8px",
                    },
                    final: {
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 0.4,
                        delay: 0.5,
                      },
                    },
                  }}
                  initial="initial"
                  animate="final"
                  src={"home-news-4.png"}
                />
              </a> */}

              <a
                href="https://www.nj.com/hudson/2023/10/hola-becomes-first-charter-school-to-receive-school-leader-award.html"
                target="_blank"
                rel="noreferrer"
                className="Home__desc__content__links__link"
              >
                <motion.img
                  variants={{
                    initial: {
                      opacity: 0,
                      y: "8px",
                    },
                    final: {
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 0.4,
                        delay: 0.5,
                      },
                    },
                  }}
                  initial="initial"
                  animate="final"
                  src={"home-news-5.png"}
                />
              </a>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

function Slide({ children, slideIndex, setIndex, scrollContainer }) {
  // const ref = useRef();
  // const isInView = useInView(ref, { root: scrollContainer });

  // useEffect(() => {
  //   console.log(`Element ${slideIndex} is in view:`, isInView);
  //   if (isInView) {
  //     setIndex(slideIndex);
  //   }
  // }, [isInView]);

  return <div className="Home__carousel__slide app-grid">{children}</div>;
}

function HomeCarousel({ scrollContainer }) {
  // console.log(scrollContainer);
  const [slideIndex, setSlideIndex] = useState(0);
  const [fixCarousel, setFixCarousel] = useState({ top: 0 });
  const ref = useRef();
  const { scrollYProgress } = useScroll({
    target: ref,
    container: scrollContainer,
    offset: ["start start", "end end"],
    layoutEffect: false,
  });

  const imageOpacity = useTransform(
    scrollYProgress,
    [0, 0.2, 0.3, 0.5, 0.6],
    [1, 0, 1, 0, 1]
  );

  const images = [
    "carousel-1.png",
    "carousel-1.png",
    "carousel-2.png",
    "carousel-3.png",
  ];

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    // console.log("Progress: ", latest);
    // const height = scrollContainer?.current?.clientHeight;
    // console.log("Height:", height * 4 * latest);
    // const rect = ref?.current?.getBoundingClientRect();
    // setFixCarousel(rect.y * -1);
    // console.log(latest);
    const percentScrolled = latest * 100;

    // Set the slideIndex
    if (percentScrolled < 20) {
      setSlideIndex(1);
    } else if (percentScrolled < 50) {
      setSlideIndex(2);
    } else {
      setSlideIndex(3);
    }

    // Set the opacity

    // Fix the carousel image bg
    if (latest < 0.01) {
      setFixCarousel({ position: "absolute", top: 0, bottom: "auto" });
    } else if (latest >= 0.01 && latest < 0.5) {
      setFixCarousel({ position: "fixed", top: 0, bottom: "auto" });
    } else if (latest >= 0.5 && latest < 0.99) {
      setFixCarousel({ position: "fixed", top: "auto", bottom: 0 });
    } else if (latest >= 0.99) {
      setFixCarousel({ position: "absolute", bottom: 0, top: "auto" });
    }
  });

  // const bgOffset = useTransform(scrollYProgress, [0, 1], [0, 100]);
  // console.log(ref);
  return (
    <div className="Home__carousel" ref={ref}>
      <motion.div className="Home__carousel__bg" style={fixCarousel}>
        <div className="Home__carousel__bg__img">
          <motion.img
            style={{ opacity: imageOpacity }}
            src={images[slideIndex]}
          />

          <img
            alt=""
            src="carousel-1.png"
            style={{
              height: "10px",
              width: "auto",
              opacity: 0,
              position: "absolute",
            }}
          />
          <img
            alt=""
            src="carousel-2.png"
            style={{
              height: "10px",
              width: "auto",
              opacity: 0,
              position: "absolute",
            }}
          />
          <img
            alt=""
            src="carousel-3.png"
            style={{
              height: "10px",
              width: "auto",
              opacity: 0,
              position: "absolute",
            }}
          />
        </div>
        <div className="Home__carousel__bg__overlay"></div>

        <div className="Home__carousel__pagination">
          <div
            className={
              "Home__carousel__pagination__page " +
              (!slideIndex || slideIndex === 1 ? "selected" : "")
            }
          />
          <div
            className={
              "Home__carousel__pagination__page " +
              (slideIndex === 2 ? "selected" : "")
            }
          />
          <div
            className={
              "Home__carousel__pagination__page " +
              (slideIndex === 3 ? "selected" : "")
            }
          />
        </div>
      </motion.div>

      <Slide
        slideIndex={0}
        setIndex={setSlideIndex}
        scrollContainer={scrollContainer}
      ></Slide>
      <Slide
        slideIndex={1}
        setIndex={setSlideIndex}
        scrollContainer={scrollContainer}
      >
        <div className="Home__carousel__slide__content">
          <p className="Home__carousel__slide__content__p">
            HoLa’s mission is to develop our students into bilingual,
            biliterate, and culturally aware global citizens who are highly
            engaged contributors to their communities.{" "}
          </p>
        </div>
      </Slide>
      <Slide
        slideIndex={2}
        setIndex={setSlideIndex}
        scrollContainer={scrollContainer}
      >
        <div className="Home__carousel__slide__content">
          <p className="Home__carousel__slide__content__p">
            A diverse faculty is critical to achieving our mission. HoLa's staff
            is 90% Hispanic and represents over 15 different countries
            throughout the Americas and Europe.
          </p>
        </div>
      </Slide>
      <Slide
        slideIndex={3}
        setIndex={setSlideIndex}
        scrollContainer={scrollContainer}
      >
        <div className="Home__carousel__slide__content">
          <p className="Home__carousel__slide__content__p">
            HoLa graduates attend the top public and private high schools and
            colleges in the U.S. and abroad. We are HoLa!
          </p>

          {/* <div className="Home__carousel__slide__content__cta">
            <a
              href={"https://www.youtube.com/watch?v=cfVW83amOmA&t=1s"}
              target="_blank"
              className="Home__carousel__slide__content__cta__icon"
            >
              <PlayIcon
                style={{
                  height: isMobile ? "40px" : "64px",
                  width: isMobile ? "40px" : "64px",
                }}
              />
            </a>

            <a
              href={"https://www.youtube.com/watch?v=cfVW83amOmA&t=1s"}
              target="_blank"
              className="Home__carousel__slide__content__cta__btn"
            >
              Watch Video
            </a>
          </div> */}
        </div>
      </Slide>
      {/* <div className="Home__carousel__content"></div> */}
    </div>
  );
}

function HomeStats() {
  const ref = useRef();
  const isInView = useInView(ref, { once: true });
  return (
    <div className="Home__stats app-grid">
      <div className="Home__stats__content">
        <div className="Home__stats__content__title">
          Inmersión en Español / Spanish Immersion
        </div>

        <div className="Home__stats__content__graphs" ref={ref}>
          <div className="Home__stats__content__graphs__graph">
            <div className="Home__stats__content__graphs__graph__donut">
              <div className="Home__stats__content__graphs__graph__donut__chart">
                {isInView && (
                  <DonutChart d={[10, 90]} colors={["#F8F8F8", "#AB1E43"]} />
                )}
              </div>
              <div className="Home__stats__content__graphs__graph__donut__legend">
                <div className="Home__stats__content__graphs__graph__donut__legend__primary">
                  90%
                </div>

                <div className="Home__stats__content__graphs__graph__donut__legend__secondary">
                  10% english
                </div>
              </div>
            </div>
            <div className="Home__stats__content__graphs__graph__donut__label">
              Grades K-2
            </div>
          </div>

          <div className="Home__stats__content__graphs__graph">
            <div className="Home__stats__content__graphs__graph__donut">
              <div className="Home__stats__content__graphs__graph__donut__chart">
                {isInView && (
                  <DonutChart d={[40, 60]} colors={["#F8F8F8", "#F27224"]} />
                )}
              </div>
              <div className="Home__stats__content__graphs__graph__donut__legend">
                <div className="Home__stats__content__graphs__graph__donut__legend__primary">
                  60%
                </div>

                <div className="Home__stats__content__graphs__graph__donut__legend__secondary">
                  40% english
                </div>
              </div>
            </div>
            <div className="Home__stats__content__graphs__graph__donut__label">
              Grade 3
            </div>
          </div>

          <div className="Home__stats__content__graphs__graph">
            <div className="Home__stats__content__graphs__graph__donut">
              <div className="Home__stats__content__graphs__graph__donut__chart">
                {isInView && (
                  <DonutChart d={[50, 50]} colors={["#F8F8F8", "#1FB1CC"]} />
                )}
              </div>
              <div className="Home__stats__content__graphs__graph__donut__legend">
                <div className="Home__stats__content__graphs__graph__donut__legend__primary">
                  50%
                </div>

                <div className="Home__stats__content__graphs__graph__donut__legend__secondary">
                  50% english
                </div>
              </div>
            </div>
            <div className="Home__stats__content__graphs__graph__donut__label">
              Grades 4-8
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function HomeRatios() {
  return (
    <div className="Home__ratios app-grid">
      <div className="Home__ratios__title">
        <div className="Home__ratios__title__primary">
          Student : Teacher Ratio
        </div>

        {/* <div className="Home__ratios__title__secondary">Grades</div> */}
      </div>

      <div className="Home__ratios__list">
        <div className="Home__ratios__list__item">
          <div className="Home__ratios__list__item__value">12:1</div>

          <div className="Home__ratios__list__item__key">K-2</div>
        </div>

        <div className="Home__ratios__list__item">
          <div className="Home__ratios__list__item__value">16:1</div>

          <div className="Home__ratios__list__item__key">3-4</div>
        </div>

        <div className="Home__ratios__list__item">
          <div className="Home__ratios__list__item__value">15:1</div>

          <div className="Home__ratios__list__item__key">5-8</div>
        </div>
      </div>
    </div>
  );
}

function HomeSubscribe() {
  // const [email, setEmail] = useState("");
  // const [success, setSuccess] = useState(false);
  // const [mutation, { loading }] = useMutation(SUBSCRIBE, {
  //   update: (cache, { data: { subscribe } }) => {
  //     if (subscribe.success) {
  //       setEmail("");
  //       setSuccess(true);
  //     }
  //   },
  // });
  return (
    <div className="Home__subscribe app-grid">
      <div className="Home__subscribe__title">
        Learn more about HoLa. Subscribe to our newsletter.
      </div>

      <div className="Home__subscribe__action">
        <a
          href={
            "https://docs.google.com/forms/d/e/1FAIpQLSf3V8SjC30DPvMFyFovjw5jYcGomCQ4oBvyZqOWsWfVPPHP4w/viewform?usp=sf_link"
          }
          target="_blank"
          rel="noreferrer"
          className="section-cta"
        >
          Subscribe now
        </a>
      </div>

      {/* {success && (
        <div className="Home__subscribe__success">
          You have been added to the subscriber's list!
        </div>
      )} */}
    </div>
  );
}

function HomeVideo() {
  return (
    <div
      className="section app-grid"
      style={{ background: "#f8f8f8", paddingBottom: "64px" }}
    >
      <div className="section-content-narrow">
        <iframe
          width="100%"
          height={isMobile ? "200px" : "400px"}
          src="https://www.youtube.com/embed/HphdnXOPz9w?si=__ata_85hpuAhjHj&amp;controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        {/* <iframe
          width="100%"
          height={isMobile ? "200px" : "400px"}
          src="https://www.youtube.com/embed/cfVW83amOmA?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
      </div>
    </div>
  );
}

function HeroFooter() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 1 });
  //
  // console.log(isInView);

  return (
    <div className="Home__hero-footer app-grid">
      <div className="Home__hero-footer__text" ref={ref}>
        <div
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Founded in 2010, Hoboken Dual Language Charter School (HoLa) is the{" "}
          <span className="bold">first</span> and only{" "}
          <span className="bold">free public</span> Spanish + English Dual
          Language <span className="bold">Charter School</span> in New Jersey.{" "}
          We educate{" "}
          <span className="bold">
            {" "}
            430+ Students from Kindergarten to Eighth Grade{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

function AboutGroup({ title, content, btn, image, link, background }) {
  return (
    <div className="About__section app-grid" style={{ background }}>
      <div className="About__section__content">
        <div className="section-title">{title}</div>
        <div className="section-p">{content}</div>
        <div className="About__section__content__cta">
          <Link to={link} className="section-cta">
            {btn}
          </Link>
        </div>
      </div>

      <div className="About__section__image">
        <img src={image} alt="" />
      </div>
    </div>
  );
}

export default function Home({ scrollContainer }) {
  usePageTracking({
    title: "Home",
  });

  return (
    <div className="Home">
      <Nav />

      <div className="Home__hero app-grid">
        <div className="Home__hero__content">
          <div className="Home__hero__heading">
            <motion.span
              variants={{
                initial: {
                  opacity: 0,
                  y: "16px",
                },
                final: {
                  opacity: 1,
                  y: "0px",
                  transition: {
                    duration: 0.2,
                    delay: 0.4,
                  },
                },
              }}
              initial="initial"
              animate="final"
            >
              We
            </motion.span>{" "}
            <motion.span
              variants={{
                initial: {
                  opacity: 0,
                  y: "16px",
                },
                final: {
                  opacity: 1,
                  y: "0px",
                  transition: {
                    duration: 0.2,
                    delay: 0.6,
                  },
                },
              }}
              initial="initial"
              animate="final"
            >
              are
            </motion.span>
          </div>

          <motion.div
            variants={{
              initial: {
                opacity: 0,
                y: "24px",
              },
              final: {
                opacity: 1,
                y: "0px",
                transition: {
                  duration: 0.5,
                  delay: 0.8,
                },
              },
            }}
            initial="initial"
            animate="final"
            className="Home__hero__logo"
          >
            <img src={"/logo-white-large.svg"} alt="" />
          </motion.div>

          <motion.div
            variants={{
              initial: {
                opacity: 0,
                y: "24px",
              },
              final: {
                opacity: 1,
                y: "0px",
                transition: {
                  duration: 0.5,
                  delay: 1,
                },
              },
            }}
            initial="initial"
            animate="final"
            className="Home__hero__subheading"
          >
            Hoboken Dual Language Charter School
          </motion.div>
        </div>
      </div>

      <HeroFooter />
      <HomeDescription />
      <HomeStats />
      <HomeRatios />
      <HomeCarousel scrollContainer={scrollContainer} />
      <HomeVideo />
      {/* <HomeStats /> */}
      {/* <HomeRatios /> */}
      {/* <HomeTimeline scrollContainer={scrollContainer} /> */}
      {/* <HomeTeam /> */}
      <AboutGroup
        title={"Innovation is a part of HoLa's DNA"}
        content={
          "Learn more about our school, leaders and how innovation powers our unique approach to education."
        }
        btn={"Learn More"}
        image={"team-preview.jpg"}
        link={"/about"}
        background={"#ffffff"}
      />
      <HomeSubscribe />
    </div>
  );
}
