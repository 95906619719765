import "./Admissions.css";
import { Hero, PageCaption, CellMeasurer } from "../../components";
import backgroundImage from "../../images/admissions-hero.jpg";
import Nav from "../../layout/Nav";
import { useInView } from "framer-motion";
import { useRef } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { LOTTERIES } from "../../api";
import { usePageTracking } from "../../utils/usePageTracking";
import { isMobile } from "react-device-detect";

function CharterSchoolsSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div className="Admissions__pcs app-grid" ref={ref}>
      <div className="Admissions__pcs__content">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          What is a Public Charter School?
        </div>
        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          New Jersey public charter schools are mission-driven public schools
          held to high financial, academic, and managerial standards by the
          designated authorizer - the New Jersey Department of Education - which
          oversees all public charter schools in the state.
        </div>
        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa’s “charter” is a contract with the New Jersey Department of
          Education, defining the school’s mission, program, performance goals
          and methods of assessments.
        </div>
      </div>
    </div>
  );
}

function JerseyCharterSchoolsSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <CellMeasurer>
      {({ squareRef, dimensions }) => (
        <div className="Admissions__pcsnj app-grid" ref={ref}>
          <div
            className="Admissions__pcsnj__title section-title"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            Public Charter Schools in New Jersey
          </div>
          <div
            ref={squareRef}
            className="Admissions__pcsnj__content"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            <div className="section-p">
              Operate on a 5 year performance contract that focuses on student
              outcomes.
            </div>
            <div className="section-p">
              Meet all state laws related to health, safety, civil rights,
              certification, academic standards and student assessment.
            </div>

            <div className="section-p">
              Follow transparency requirements such as public records and open
              meetings laws.
            </div>

            <div className="section-p">
              Must meet specific financial, academic and operational standards.
            </div>

            <div className="section-p">
              Governed by an independent, non-profit Board of Trustees comprised
              of volunteer members.
            </div>
          </div>

          <div
            className="Admissions__pcsnj__image"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              height: dimensions.height,
              width: "auto",
            }}
          >
            <img
              src="/admissions-1.png"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt=""
            />
          </div>
        </div>
      )}
    </CellMeasurer>
  );
}

function VisitHola() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  // const currentYear = moment().year() + "";
  // const nextYear = moment().add(1, "y").year() + "";

  // const { data } = useQuery(LOTTERIES, {
  //   variables: {
  //     where: {
  //       year: {
  //         in: [currentYear, nextYear],
  //       },
  //     },
  //   },
  // });

  // const lotteries = data?.lotteries || [];
  // const currentYearLottery = lotteries.find((x) => x.year === currentYear);
  // const nextYearLottery = lotteries.find((x) => x.year === nextYear);

  // const selectedLottery = moment().isAfter(currentYearLottery?.deadline)
  //   ? nextYearLottery
  //   : currentYearLottery;

  return (
    <div
      className="Visit-hola app-grid"
      ref={ref}
      style={
        {
          // paddingBottom: 0,
          // paddingTop: "48px",
          // background: "#000000",
        }
      }
    >
      <div
        className="Visit-hola__content"
        style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        <div
          className="Visit-hola__section"
          style={{
            borderRightColor: isMobile
              ? "transparent"
              : "rgba(255,255,255,0.5)",
          }}
        >
          <div className="Visit-hola__section__primary">SCHOOL TOURS</div>

          <div className="Visit-hola__section__secondary">
            <div>November 6, 8AM</div>
            <div>December 4, 8AM</div>
          </div>
        </div>

        <div
          className="Visit-hola__section"
          style={{
            borderRightColor: isMobile
              ? "transparent"
              : "rgba(255,255,255,0.5)",
          }}
        >
          <div className="Visit-hola__section__primary">OPEN HOUSE</div>

          <div className="Visit-hola__section__secondary">
            November 16, 11AM
          </div>
        </div>

        <div className="Visit-hola__section">
          <div className="Visit-hola__section__primary">VIRTUAL OPEN HOUSE</div>

          <div className="Visit-hola__section__secondary">
            January 8, 6:30PM
          </div>
        </div>

        <div className="Visit-hola__section cta">
          <a
            className="Visit-hola__section__cta"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeMfRS1cwG7UiEV0e8v6WuGI213rgwh1Y4aDbQJutZyOwTlpg/viewform"
            target="_blank"
            rel="noreferrer"
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}

function LotterySection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const currentYear = moment().year() + "";
  const nextYear = moment().add(1, "y").year() + "";

  const { data } = useQuery(LOTTERIES, {
    variables: {
      where: {
        year: {
          in: [currentYear, nextYear],
        },
      },
    },
  });

  const lotteries = data?.lotteries || [];
  const currentYearLottery = lotteries.find((x) => x.year === currentYear);
  const nextYearLottery = lotteries.find((x) => x.year === nextYear);

  const selectedLottery = moment().isAfter(currentYearLottery?.drawing)
    ? nextYearLottery
    : currentYearLottery;

  return (
    <CellMeasurer>
      {({ squareRef, dimensions }) => (
        <div className="Admissions__lottery app-grid" ref={ref}>
          <div
            className="Admissions__lottery__title section-title"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            HoLa’s Admissions Lottery
          </div>

          <div className="Admissions__lottery__dates">
            <div
              className="Admissions__lottery__dates__date"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              <div className="Admissions__lottery__dates__date__label">
                LOTTERY OPEN
              </div>

              <div className="Admissions__lottery__dates__date__value">
                {/* {moment(selectedLottery?.opening).format(
                  "MMMM DD, YYYY [at] h:mm a"
                )} */}
                October 1, 2024 at 9AM
              </div>
            </div>

            <div
              className="Admissions__lottery__dates__date"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                textAlign: "center",
              }}
            >
              <div className="Admissions__lottery__dates__date__label">
                LOTTERY DEADLINE
              </div>

              <div className="Admissions__lottery__dates__date__value">
                {/* {moment(selectedLottery?.deadline).format(
                  "MMMM DD, YYYY [at] h:mm a"
                )} */}
                January 9, 2025 at 5PM
              </div>
            </div>

            <div
              className="Admissions__lottery__dates__date"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              <div className="Admissions__lottery__dates__date__label">
                LOTTERY DRAWING
              </div>

              <div className="Admissions__lottery__dates__date__value">
                {/* {moment(selectedLottery?.drawing).format(
                  "MMMM DD, YYYY [at] h:mm a"
                )} */}
                January 23, 2025 at 9AM
              </div>
            </div>
          </div>

          <div className="Admissions__lottery__content" ref={squareRef}>
            <div
              className="Admissions__lottery__content__text section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              HoLa is a FREE and public charter school that is open to all.
              Admission to HoLa is based on a random lottery held annually in
              January for the upcoming school year.
            </div>

            <div
              className="Admissions__lottery__content__text section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              No previous exposure to a second language is required. A waitlist
              is created in case seats open.
            </div>

            <div
              className="Admissions__lottery__content__action"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              <a
                href="https://app.lotterease.com/cgi-bin/sl_V2_1.pl?nav=Parents_Registration&account_id=879"
                className="Admissions__lottery__content__action__btn"
                target={"_blank"}
                rel="noreferrer"
              >
                Apply now
              </a>
            </div>
          </div>

          <div
            className="Admissions__lottery__image"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",

              // maxHeight: dimensions.height,
            }}
          >
            <img src={"admissions-2.png"} style={{ maxWidth: "100%" }} alt="" />
          </div>
        </div>
      )}
    </CellMeasurer>
  );
}

function LotteryPreferencesSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div className="Admissions__lottery-preferences" ref={ref}>
      <div
        className="Admissions__lottery-preferences__section"
        style={{
          background: "#F27224",
          color: "white",
          padding: "4%",
          display: "grid",
          gridTemplateColumns: "repeat(6, minmax(0,1fr))",
        }}
      >
        <div
          className="Admissions__lottery-preferences__section__title section-title"
          style={{
            gridColumnStart: 2,
            gridColumnEnd: 7,
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Lottery Preferences
        </div>
        <div
          className="Admissions__lottery-preferences__section__text section-p"
          style={{
            gridColumnStart: 2,
            gridColumnEnd: 7,
            paddingRight: "40px",
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            marginBottom: "0px",
          }}
        >
          The priority order for lottery admission and the waitlists is:
        </div>
        <ol
          className="Admissions__lottery-preferences__section__list"
          style={{
            gridColumnStart: 2,
            gridColumnEnd: 7,
            paddingRight: "40px",
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          <li>Siblings of students who are currently enrolled at HoLa </li>
          <li>Hoboken residents </li>
          <li>All out-of-district (non-Hoboken) residents</li>
        </ol>
      </div>

      <div
        className="Admissions__lottery-preferences__section"
        style={{
          background: "#F8F8F8",
          padding: "4%",
          display: "grid",
          gridTemplateColumns: "repeat(6, minmax(0,1fr))",
        }}
      >
        <div
          className="Admissions__lottery-preferences__section__title section-title"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 6,
            paddingLeft: "60px",
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Enrollment
        </div>
        <div
          className="Admissions__lottery-preferences__section__text section-p"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 6,
            paddingLeft: "60px",
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          If, by the application deadline, applications exceed the number of
          spaces available for a given grade, HoLa hosts a public lottery. All
          selected students are notified immediately and must submit a proof of
          residency within 48 hours.
        </div>
      </div>
    </div>
  );
}

function WeightedLotterySection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div className="Admissions__weighted-lottery app-grid" ref={ref}>
      <div className="Admissions__weighted-lottery__content">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Weighted Lottery
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          In 2015, HoLa became the first charter school in New Jersey to
          implement a weighted lottery system in favor of
          economically-disadvantaged families - as approved by the NJ Department
          of Education. In 2018, the NJ Department of Education approved HoLa
          expanding its weighted lottery preference to include English Language
          Learners.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Today, HoLa still offers a 3x application weight for families who
          qualify for either type of lottery preference (one weighted preference
          per applicant). Parents must show proof of qualification before the
          lottery deadline.
        </div>

        <div className="Admissions__weighted-lottery__content__items">
          <div
            className="Admissions__weighted-lottery__content__items__item"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            <div className="Admissions__weighted-lottery__content__items__item__icon">
              1
            </div>
            <div className="Admissions__weighted-lottery__content__items__item__text">
              <div className="Admissions__weighted-lottery__content__items__item__text__primary">
                <div>Economic Disadvantage </div>
                <div>Weighted Lottery</div>
              </div>

              <div className="Admissions__weighted-lottery__content__items__item__text__secondary">
                Child lives in public or Section 8 housing. Child/family
                receives SNAP and/or qualifies for free or reduced price lunch.
              </div>
            </div>
          </div>

          <div
            className="Admissions__weighted-lottery__content__items__item"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            <div className="Admissions__weighted-lottery__content__items__item__icon">
              2
            </div>
            <div className="Admissions__weighted-lottery__content__items__item__text">
              <div className="Admissions__weighted-lottery__content__items__item__text__primary">
                <div>English Language Learner </div>
                <div>Weighted Lottery</div>
              </div>

              <div className="Admissions__weighted-lottery__content__items__item__text__secondary">
                The child is neither a native English speaker nor able to
                communicate fluently or learn effectively in English.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WaitlistsSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <CellMeasurer>
      {({ squareRef, dimensions }) => (
        <div className="Admissions__waitlists app-grid" ref={ref}>
          <div className="Admissions__waitlists__content" ref={squareRef}>
            <div
              className="section-title"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              Waitlists
            </div>
            <div
              className="section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              Once all seats are filled through the lottery, applicants are
              placed on the waitlist. Any applications submitted after the
              deadline are added to the end of the waitlist in the order
              received. The waitlist remains active for the school year for
              which the student has applied. The State Department of Education
              requires charter schools to reset waitlists each school year.
            </div>
            <div
              className="Admissions__waitlists__content__action"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              <a
                href={
                  "https://app.lotterease.com/cgi-bin/sl_V2_1.pl?session_id=&user_id=&account_id=879&nav=Parents_Applicants_List"
                }
                target={"_blank"}
                rel="noreferrer"
                className="Admissions__waitlists__content__action__btn"
              >
                Check the Waitlist
              </a>
            </div>
          </div>

          <div
            className="Admissions__waitlists__image"
            style={{
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              height: dimensions.height,
              width: "auto",
            }}
          >
            <img src={"admissions-3.png"} alt="" />
          </div>
        </div>
      )}
    </CellMeasurer>
  );
}

function RegistrationSection() {
  return (
    <div className="Admissions__registration">
      <a
        href="https://app.lotterease.com//cgi-bin/sl_V2_1.pl?nav=Parents_Registration&account_id=879"
        className="Admissions__registration__btn"
        target={"_blank"}
        rel="noreferrer"
      >
        Apply Now
      </a>
    </div>
  );
}

function HomeSubscribe() {
  // const [email, setEmail] = useState("");
  // const [success, setSuccess] = useState(false);
  // const [mutation, { loading }] = useMutation(SUBSCRIBE, {
  //   update: (cache, { data: { subscribe } }) => {
  //     if (subscribe.success) {
  //       setEmail("");
  //       setSuccess(true);
  //     }
  //   },
  // });
  return (
    <div className="Home__subscribe app-grid">
      <div className="Home__subscribe__title">
        Learn more about HoLa. Subscribe to our newsletter.
      </div>

      <div className="Home__subscribe__action">
        <a
          href={
            "https://docs.google.com/forms/d/e/1FAIpQLSf3V8SjC30DPvMFyFovjw5jYcGomCQ4oBvyZqOWsWfVPPHP4w/viewform?usp=sf_link"
          }
          target="_blank"
          rel="noreferrer"
          className="section-cta"
        >
          Subscribe now
        </a>
      </div>

      {/* {success && (
        <div className="Home__subscribe__success">
          You have been added to the subscriber's list!
        </div>
      )} */}
    </div>
  );
}

export default function Admissions() {
  usePageTracking({
    title: "Admissions",
  });

  return (
    <div className="Admissions">
      <Nav />
      <Hero image={backgroundImage} text={"Admissions"} />
      <VisitHola />
      <PageCaption
        text={
          <span>
            <span className="bold">
              Charter schools are tuition-free, public schools open to all
              students
            </span>{" "}
            regardless of zip code, race/ethnicity or ability level. To apply
            for the current school year (2024-2025), please email
            admissions@holahoboken.org.
          </span>
        }
        options={[
          {
            label: "Apply now",
            to: "https://app.lotterease.com/cgi-bin/sl_V2_1.pl?nav=Parents_Registration&account_id=879",
            type: "external",
          },
          {
            label: "Waitlist",
            to: "https://app.lotterease.com/cgi-bin/sl_V2_1.pl?session_id=&user_id=&account_id=879&nav=Parents_Applicants_List",
            type: "external",
          },
        ]}
      />

      <CharterSchoolsSection />

      <JerseyCharterSchoolsSection />

      <div className="PageCaption app-grid">
        <div
          className="PageCaption__content"
          style={{ justifyContent: "center" }}
        >
          <div className="PageCaption__text">
            For more information about Charters visit{" "}
            <a
              style={{ color: "var(--black)" }}
              href={"https://njcharters.org/about/"}
              target="_blank"
              className="bold"
              rel="noreferrer"
            >
              New Jersey Public Charter Schools Association.
            </a>
          </div>
        </div>
      </div>

      <LotterySection />

      <LotteryPreferencesSection />

      <WeightedLotterySection />

      <WaitlistsSection />

      <RegistrationSection />
      <HomeSubscribe />
    </div>
  );
}
