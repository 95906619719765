import "./Donate.css";
import { Hero, PageCaption } from "../../components";
import Nav from "../../layout/Nav";
import backgroundImage from "../../images/donate-hero.jpg";
import { isMobile } from "react-device-detect";
import { usePageTracking } from "../../utils/usePageTracking";

export default function Donate() {
  usePageTracking({
    title: "Donate",
  });

  return (
    <div className="Donate">
      <Nav />
      <Hero image={backgroundImage} text={"Give"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              Every dollar raised enhances our children’s school experience and
              supports our educators in fulfilling the school’s mission of
              delivering a world-class bilingual education.
            </span>
          </span>
        }
      />

      <div className="section app-grid">
        <div className="section-content">
          <div className="section-p">
            As one of New Jersey’s top-performing schools, HoLa ranks in the top
            5%, serving nearly 15% of Hoboken's K-8 students. Yet, it receives
            the lowest per-student funding in the state, placing it in the
            bottom 5%.
          </div>

          <div className="section-p">
            Thanks to the generosity of our community, HoLa continues to provide
            a world-class bilingual education. We rely on donations from
            families and supporters, with each contribution directly enhancing
            our students' experience and empowering our educators to fulfill the
            school’s mission. We sincerely thank you in advance for your
            support.
          </div>
        </div>
      </div>

      {/* <div className="Donate__subheading">
        We thank you in advance for your generous donations.
      </div> */}

      <div className="section app-grid">
        {isMobile ? (
          <div
            className="Donate__mobile-image-wrapper"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 13,
              paddingLeft: "16px",
              paddingRight: "16px",
              overflowX: "auto",
            }}
          >
            <img src="/donate-1.png" alt="" className="Donate__image" />
          </div>
        ) : (
          <div className="section-content">
            <img src="/donate-1.png" alt="" className="Donate__image" />
          </div>
        )}
      </div>

      {/* <div className="Donate__subheading">
        <div className="Donate__subheading__line">
          Please consider an Innovation Gift of $2,010.{" "}
        </div>
        <div className="Donate__subheading__line">
          This level recognizes the year that HoLa was founded, 2010.
        </div>
      </div> */}

      <div className="section app-grid">
        <div
          className="Donate__form "
          style={{ gridColumnStart: 1, gridColumnEnd: 13 }}
        >
          <iframe
            onload="window.parent.scrollTo(0,0)"
            height="1540"
            allowTransparency="true"
            allow="payment"
            frameBorder="0"
            scrolling="no"
            title="donate-iframe"
            style={{ width: "100%", border: "none" }}
            src="https://secure.lglforms.com/form_engine/s/MToPYbhZYApjKFf2vTVgnA"
          >
            <a href="https://secure.lglforms.com/form_engine/s/MToPYbhZYApjKFf2vTVgnA">
              Fill out my LGL Form!
            </a>
          </iframe>
        </div>
      </div>
    </div>
  );
}
